/* You can add global styles to this file, and also import other style files */

* {    
    box-sizing: border-box;
    font-family: 'Open Sans';
}

html,
body {
    height: 100%;
    color: #001233;
}

body {
    margin: 0;
    font-family: 'Open Sans', Roboto, "Helvetica Neue", sans-serif;

    .tp,
    .tooltip {
        font-size: 12px;
    }

    .tp-hidden {
        display: none;        
    }

    button {
        outline: 0;
    }

    ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }
    
    ::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: rgba(74, 86, 81, .4);
        -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    
        &:hover {
            background-color: rgba(4, 102, 200, .3);
        }
    }
    
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}